.container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.container h1 {
  color: #888888;
}

.container button {
  padding: 10px 20px;
  background: rgb(255 255 255);
  border-radius: 20px;
  width: 250px;
  margin: 10px 0;
  cursor: pointer;
}

.container button:hover {
  background: rgb(242, 241, 241);
}
