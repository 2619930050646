
.ContentsWrapper {
  width: 100%;
  min-height: calc(100vh - 65px);
  background: #f4f4f4;
}

.ContentsArea {
  width: 100%;
  padding: 8px;
}

.orderWrapper {
  height: fit-content;
  display: flex;
}
.listItemOrderCard {
  display: flex;
  flex-direction: column;
  padding: 6px;
}

.orderData {
  width: 100%;
  padding: 10px;
}
.titleView {
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #747474;
}

.value {
  padding-left: 5px;
}
.orderDetailsBtn {
  color: #a1a1a1;
  font-size: 28px;
  cursor: pointer;
}
.backToMenu {
  display: flex;
  align-items: center;
  padding: 6px;
  color: #747474;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
}

