html {
    height: 100%;
}

body {
    background-repeat: no-repeat;
    background-size: cover;
    color: #0e0d0d;
    font-family: 'Red Hat Display', sans-serif;
    padding: 0;
    margin: 0;
    height: 100%;
    letter-spacing: 1.4px;
    line-height: 1.8;
}

a {
    text-decoration: none;
    color: #0d0dca;
}

a:hover {
    color: #132685;
}

.contents {
    background-size: cover;
    height: 100%;
    overflow-y: auto;
}

.contents-wrapper {
    background-color: rgba(236, 236, 236, 0.9);
    padding: 5vw 5vw 1vw 5vw;
    min-height: 100%;
}

.section-gap {
    margin-bottom: 3vw;
}

h1, h2 {
    color: #1c1a1a;
}

h1 {
    font-size: 2.5em;
    margin-bottom: 1.5vw;
}

h2 {
    font-size: 2em;
    margin-top: 2vw;
    margin-bottom: 1vw;
}

ul {
    margin-top: 1vw;
    margin-bottom: 1vw;
    list-style-type: none;
    padding-left: 0;
}

ul li {
    margin-bottom: 0.5vw;
}

ul li:before {
    content: "•";
    margin-right: 0.8vw;
    color: #191e1b;
}

.contact-us-footer-box {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-top: solid 1px #0d0d2f;
    align-items: center;
    padding: 2vw 0;
    color: #fff;
}

.contactus-footer-nav {
    display: flex;
    flex-direction: row;
    gap: 3vw;
}

.contactus-terms-links {
    display: flex;
    flex-direction: row;
    gap: 10px;
    font-size: 12px;
    justify-content: flex-end;
}

.contact-us-footer-copyright {
    color: #1e1e21;
    padding-top: 10px;
}

.contactus-footer-nav a,
.contactus-terms-links a {
    color: #1a1818;
    font-size: 14px;
}

.contactus-footer-nav a:hover,
.contactus-terms-links a:hover {
    color: #001d92;
}

.queries-wrapper {
    display: flex;
    align-items: center;
    margin-top: 1vw;
}

.queries_icon {
    width: 43px;
    height: 40px;
    padding-right: 10px;
}

.queries_text {
    font-size: 16px;
    color: #fff;
}
