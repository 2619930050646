@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Reddit+Sans:ital,wght@0,200..900;1,200..900&family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

.container {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Inter', sans-serif !important;
  background-color: rgb(239, 239, 239);
  display: flex;
  align-items: center;
  justify-content: center;
}
.statusContainter {
  padding: 20px;
  max-width: 900px;
}
.icon {
  height: 8rem !important;
  width: 8rem !important;
  color: #236632;
}
.subHead {
  font-size: 15px;
}
.text {
  font-size: 12.5px;
  text-transform: capitalize;
}
.subText {
  font-size: 12.5px;
  padding-left: 10px;
}
.title {
  border-top: dotted gray 3px;
}
.title span {
  font-size: 13px;
  font-weight: bold;
  padding: 5px 0;
}
.orderContainer {
  border-top: dotted gray 3px;
  margin-top: 20px !important;
}
.subTitle {
  font-size: 18px;
  color: rgb(128 128 128);
  font-style: italic;
  margin-top: 13px;
}
.heading {
  font-size: 32px;
  padding: 15px;
  text-align: center;
}
.btn {
  background-color: rgb(0, 146, 63);
  padding: 9px 26px;
  margin-top: 10px;
  border-radius: 20px;
  color: #f1f1f1;
  border: none;
  cursor: pointer;
}
.button {
  background-color: rgb(0, 146, 63) !important;
  padding: 9px 26px !important;
  margin-top: 10px !important;
  border-radius: 10px !important;
  color: #f1f1f1 !important;
  width: fit-content !important;
}
.error {
  font-size: 26px;
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 24px;
  }
}

@media print {
  .btn {
    display: none;
  }
  .heading {
    display: none;
  }
}
