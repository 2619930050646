.productImgParent {
  height: 150px;
  max-width: 150px;
  overflow: hidden;
  text-align: center;
  margin: 0 auto;
}
.productImage {
  width: 90%;
  height: 90%;
}
.productImage:hover {
  transform: scale(1.05);
}
.flexParent {
  margin-top: 4px;
}
.flexLeft {
  justify-content: flex-start;
  color: #2f333a;
  font-size: 14px;
  font-weight: 500;
}
.footer {
  font-size: 16px;
  font-weight: 600;
}
.priceOld {
  color: #e02c2b;
  margin-left: 12px;
  font-size: 16px;
  text-align: center;
  text-decoration: line-through;
}
.flexChildRight {
  font-size: 15px;
  display: flex;
  width: 100%;
}

.cartQuantityText {
  font-size: 18px;
  font-weight: 500;
  color: white;
}

.productCard {
  position: relative;
  height: 260px;
  overflow: hidden;
}
.productCardTop {
  padding: 10px;
  height: calc(100% - 38px);
}
.cartButton {
  height: 38px;
  width: 100%;
  color: #fff;
  font-size: 14px;
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.buttonAddToCart {
  background: none;
  width: 100%;
  height: 100%;
  border: none;
}
.addToCartBtnText {
  color: #fff;
  font-weight: bold;
  cursor: pointer;
}
.soldOut {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center; 
  justify-content: center; 
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 10; 
}
.soldOutText {
  color: #e02c2b;
  font-size: 16px;
  text-align: center;
}

@media (max-width: 460px) {
  .productCard {
    height: 190px;
  }
  .productCardTop{
    padding: 4px;
    margin: 2px;
  }
  .productImgParent {
    height: 95px;
    max-width: 130px;
    padding: 0;
  }
  .productImage {
    width: 70%;
    height: 90%;
  }
  .footer {
    font-size: 12px; 
  }
  .cartButton {
    height: 35px;
    font-size: 12px;
  }
  .flexLeft h3{
    font-size: 11px;
    line-height: 14px;
    padding-bottom: 2px;
    padding-top: 1px;
  }
  .addToCartBtnText {
    font-size: smaller;
  }
}