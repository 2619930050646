.breadcrums {
  color: #777;
  margin: 20px 0;
  font-weight: 600;
}
.breadcrums span {
  cursor: pointer;
}
.productDetailWrapper {
  padding: 70px 0;
}
.productImg {
  width: 100%;
  height: 100%;
}
.productImgDiv {
  /* padding: 4px; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100%;
  height: 310px;
  position: relative;
}
.productName {
  margin-top: 0;
  margin-bottom: 20px;
}
.cartButton {
  color: #000;
  font-size: 14px;
  display: flex;
  align-items: center;
}
.buttonAddToCart {
  background: none;
  width: 100%;
  height: 100%;
  border: none;
}

.addToCartBtnText {
  color: #000;
  font-weight: bold;
  cursor: pointer;
}

.cartQuantityText {
  margin: 0 0 0 20px;
  font-size: 18px;
  font-weight: bold;
}

.cartQuantityEditLayout {
  margin: 0 20px 0 10px;
  cursor: pointer;
}

.productMrp {
  color: #f00;
  font-size: 18px;
  font-weight: 600;
  text-decoration: line-through;
}

.productPrice {
  font-size: 16px;
  font-weight: 600;
  margin-left: 15px;
}
.priceWrapper {
  margin: 20px 0;
}
.soldOut {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  align-items: center; 
  justify-content: center; 
  background: rgba(0, 0, 0, 0.5);
  color: white;
  z-index: 10; 
}
.soldOutText {
  color: #e02c2b;
  font-size: 16px;
  text-align: center;
}


@media (max-width: 600px) {
  .productDetailWrapper {
    padding: 30px 0;
  }
  .productContentDiv {
    padding-top: 30px;
  }
}
