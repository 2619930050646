@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Reddit+Sans:ital,wght@0,200..900;1,200..900&family=Ubuntu+Sans:ital,wght@0,100..800;1,100..800&display=swap');

.ContentWrapper {
  min-height: calc(100vh - 65px);
  padding: 10px;
  background: #f4f4f4;
}
.value {
  padding-left: 5px;
}
.contentsPaper {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 8px;
}
.detailsContainer {
  padding: 15px;
}
.infoContainer {
  padding-bottom: 6px;
}
.qrContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
}
.backToMenu {
  display: flex;
  align-items: center;
  padding: 6px;
  color: #747474;
  font-size: 14px;
  margin: 10px 0;
  cursor: pointer;
}

.container {
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  font-family: 'Inter', sans-serif !important;
  background-color: rgb(239, 239, 239);
  margin: 0 auto;
}
.statusContainter {
  padding: 20px;
  max-width: 900px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: auto;
}
.icon {
  height: 8rem !important;
  width: 8rem !important;
  color: #236632;
}
.subHead {
  font-size: 15px;
}
.text {
  font-size: 12.5px;
  text-transform: capitalize;
}
.subText {
  font-size: 12.5px;
  padding-left: 10px;
}
.title {
  border-top: dotted gray 3px;
}
.title span {
  font-size: 13px;
  font-weight: bold;
  padding: 5px 0;
}
.orderContainer {
  border-top: dotted gray 3px;
  margin-top: 20px !important;
}
.subTitle {
  font-size: 18px;
  color: rgb(128 128 128);
  font-style: italic;
  margin-top: 13px;
}
.heading {
  font-size: 32px;
  padding: 15px;
}
.btn {
  background-color: rgb(0, 146, 63);
  padding: 9px 26px;
  margin-top: 10px;
  border-radius: 20px;
  color: #f1f1f1;
  border: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .heading {
    font-size: 24px;
  }
}

@media print {
  .btn {
    display: none;
  }
  .heading {
    display: none;
  }
}
