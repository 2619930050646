.titleBarLayout {
  display: flex;
  flex-wrap: nowrap;
  height: 65px;
  align-items: center;
}
.logoText {
  font-weight: bold;
  font-size: 28px;
  color: white;
  margin-top: 0;
  display: flex;
  justify-content: space-around;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  padding-top: 10px;
}
.logoWrapper {
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}
.searchWrapper {
  align-items: center;
}
.cartLogo {
  color: white;
  cursor: pointer;
}
.profileTextWrapper {
  margin-right: 0;
}
.profileTextMain {
  font-weight: bold;
  font-size: 15px;
  color: #fff;
  margin-right: 25px;
}
.profileTextSub {
  font-size: 14px;
  color: grey;
}
.shopListPopup {
  color: #72777a;
  font-size: 16px;
  background-color: white;
  border: 0.2px solid #e5e5e5;
  position: absolute;
  right: 24px;
  top: 75px;
  z-index: 100;
}
.shopListItem {
  padding: 15px 30px;
}
.searchInput {
  width: 100%;
  padding-left: 20px;
  border-radius: 5px;
  height: 40px;
  border: none transparent;
  background-color: white;
}
textarea:focus,
input:focus {
  outline: none;
}

@media (max-width: 460px) {
  .searchInput{
    width: 90%;
    margin-left: 20px;
  }
}